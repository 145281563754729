import Permissions from 'src/security/permissions';
import { i18n } from 'src/i18n';
import React from 'react';
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PersonIcon from '@material-ui/icons/Person';
import HistoryIcon from '@material-ui/icons/History';
import SettingsIcon from '@material-ui/icons/Settings';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Event from '@material-ui/icons/Event';
import Alarm from '@material-ui/icons/Alarm';
import Build from '@material-ui/icons/Build';
import SportsHockey from '@material-ui/icons/SportsHockey';
import config from 'src/config';

const permissions = Permissions.values;

export default [
  {
    path: '/',
    exact: true,
    icon: <DashboardIcon />,
    label: i18n('dashboard.menu'),
    permissionRequired: null,
  },

  config.isPlanEnabled && {
    path: '/plan',
    permissionRequired: permissions.planRead,
    icon: <CreditCardOutlinedIcon />,
    label: i18n('plan.menu'),
  },
  {
    path: '/user',
    exact: true,
    label: i18n('user.menu'),
    permissionRequired: permissions.userRead,
    icon: <PersonIcon />,
  },
  {
    path: '/member',
    permissionRequired: permissions.memberRead,
    icon: <SportsHockey />,
    label: i18n('entities.member.menu'),
  },
  {
    path: '/work-type',
    exact: true,
    permissionRequired: permissions.workTypeRead,
    icon: <Build />,
    label: i18n('entities.workType.menu'),
  },

  {
    path: '/work-hours',
    exact: true,
    permissionRequired: permissions.workHoursRead,
    icon: <Alarm />,
    label: i18n('entities.workHours.menu'),
  },

  {
    path: '/settings',
    exact: true,
    icon: <SettingsIcon />,
    label: i18n('settings.menu'),
    permissionRequired: permissions.settingsEdit,
  },

  {
    path: '/audit-logs',
    exact: true,
    icon: <HistoryIcon />,
    label: i18n('auditLog.menu'),
    permissionRequired: permissions.auditLogRead,
  },
].filter(Boolean);
