import Permissions from 'src/security/permissions';
import config from 'src/config';
const permissions = Permissions.values;

const privateRoutes = [
  {
    path: '/',
    loader: () =>
      import('src/view/dashboard/DashboardPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/profile',
    loader: () => import('src/view/auth/ProfileFormPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/password-change',
    loader: () =>
      import('src/view/auth/PasswordChangeFormPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/tenant',
    loader: () =>
      import('src/view/tenant/list/TenantListPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/tenant/new',
    loader: () =>
      import('src/view/tenant/form/TenantFormPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/tenant/:id/edit',
    loader: () =>
      import('src/view/tenant/form/TenantFormPage'),
    permissionRequired: null,
    exact: true,
  },

  config.isPlanEnabled && {
    path: '/plan',
    loader: () => import('src/view/plan/PlanPage'),
    permissionRequired: permissions.planRead,
    exact: true,
  },

  {
    path: '/user',
    loader: () => import('src/view/user/list/UserPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/user/new',
    loader: () => import('src/view/user/new/UserNewPage'),
    permissionRequired: permissions.userCreate,
    exact: true,
  },

  {
    path: '/user/importer',
    loader: () =>
      import('src/view/user/importer/UserImporterPage'),
    permissionRequired: permissions.userImport,
    exact: true,
  },
  {
    path: '/user/:id/edit',
    loader: () => import('src/view/user/edit/UserEditPage'),
    permissionRequired: permissions.userEdit,
    exact: true,
  },
  {
    path: '/user/:id',
    loader: () => import('src/view/user/view/UserViewPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/audit-logs',
    loader: () => import('src/view/auditLog/AuditLogPage'),
    permissionRequired: permissions.auditLogRead,
  },

  {
    path: '/settings',
    loader: () =>
      import('src/view/settings/SettingsFormPage'),
    permissionRequired: permissions.settingsEdit,
  },
  
  {
    path: '/work-type',
    loader: () =>
      import('src/view/workType/list/WorkTypeListPage'),
    permissionRequired: permissions.workTypeRead,
    exact: true,
  },
  {
    path: '/work-type/new',
    loader: () =>
      import('src/view/workType/form/WorkTypeFormPage'),
    permissionRequired: permissions.workTypeCreate,
    exact: true,
  },
  {
    path: '/work-type/importer',
    loader: () =>
      import(
        'src/view/workType/importer/WorkTypeImporterPage'
      ),
    permissionRequired: permissions.workTypeImport,
    exact: true,
  },
  {
    path: '/work-type/:id/edit',
    loader: () =>
      import('src/view/workType/form/WorkTypeFormPage'),
    permissionRequired: permissions.workTypeEdit,
    exact: true,
  },
  {
    path: '/work-type/:id',
    loader: () =>
      import('src/view/workType/view/WorkTypeViewPage'),
    permissionRequired: permissions.workTypeRead,
    exact: true,
  },

  {
    path: '/work-hours',
    loader: () =>
      import('src/view/workHours/list/WorkHoursListPage'),
    permissionRequired: permissions.workHoursRead,
    exact: true,
  },
  {
    path: '/work-hours/new',
    loader: () =>
      import('src/view/workHours/form/WorkHoursFormPage'),
    permissionRequired: permissions.workHoursCreate,
    exact: true,
  },
  {
    path: '/work-hours/importer',
    loader: () =>
      import(
        'src/view/workHours/importer/WorkHoursImporterPage'
      ),
    permissionRequired: permissions.workHoursImport,
    exact: true,
  },
  {
    path: '/work-hours/:id/edit',
    loader: () =>
      import('src/view/workHours/form/WorkHoursFormPage'),
    permissionRequired: permissions.workHoursEdit,
    exact: true,
  },
  {
    path: '/work-hours/:id',
    loader: () =>
      import('src/view/workHours/view/WorkHoursViewPage'),
    permissionRequired: permissions.workHoursRead,
    exact: true,
  },

  {
    path: '/member',
    loader: () =>
      import('src/view/member/list/MemberListPage'),
    permissionRequired: permissions.memberRead,
    exact: true,
  },
  {
    path: '/member/new',
    loader: () =>
      import('src/view/member/form/MemberFormPage'),
    permissionRequired: permissions.memberCreate,
    exact: true,
  },
  {
    path: '/member/importer',
    loader: () =>
      import(
        'src/view/member/importer/MemberImporterPage'
      ),
    permissionRequired: permissions.memberImport,
    exact: true,
  },
  {
    path: '/member/:id/edit',
    loader: () =>
      import('src/view/member/form/MemberFormPage'),
    permissionRequired: permissions.memberEdit,
    exact: true,
  },
  {
    path: '/member/:id',
    loader: () =>
      import('src/view/member/view/MemberViewPage'),
    permissionRequired: permissions.memberRead,
    exact: true,
  },
].filter(Boolean);

const publicRoutes = [
  {
    path: '/auth/signin',
    loader: () => import('src/view/auth/SigninPage'),
  },
  {
    path: '/auth/signup',
    loader: () => import('src/view/auth/SignupPage'),
  },
  {
    path: '/auth/forgot-password',
    loader: () =>
      import('src/view/auth/ForgotPasswordPage'),
  },
].filter(Boolean);

const emptyTenantRoutes = [
  {
    path: '/auth/tenant',
    loader: () => import('src/view/auth/TenantPage'),
  },
].filter(Boolean);

const emptyPermissionsRoutes = [
  {
    path: '/auth/empty-permissions',
    loader: () =>
      import('src/view/auth/EmptyPermissionsPage'),
  },
].filter(Boolean);

const emailUnverifiedRoutes = [
  {
    path: '/auth/email-unverified',
    loader: () =>
      import('src/view/auth/EmailUnverifiedPage'),
  },
].filter(Boolean);

const simpleRoutes = [
  {
    path: '/auth/password-reset',
    loader: () => import('src/view/auth/PasswordResetPage'),
  },
  {
    path: '/auth/invitation',
    loader: () => import('src/view/auth/InvitationPage'),
  },
  {
    path: '/auth/verify-email',
    loader: () => import('src/view/auth/VerifyEmailPage'),
  },
  {
    path: '/403',
    loader: () =>
      import('src/view/shared/errors/Error403Page'),
  },
  {
    path: '/500',
    loader: () =>
      import('src/view/shared/errors/Error500Page'),
  },
  {
    path: '**',
    loader: () =>
      import('src/view/shared/errors/Error404Page'),
  },
].filter(Boolean);

export default {
  privateRoutes,
  publicRoutes,
  emptyTenantRoutes,
  emptyPermissionsRoutes,
  emailUnverifiedRoutes,
  simpleRoutes,
};
