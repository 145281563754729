import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.workHours.fields.id'),
  },
  {
    name: 'member',
    label: i18n('entities.member.fields.memberId'),
    render: exporterRenders.relatedField('memberId'),
  },
  {
    name: 'status',
    label: i18n('entities.workHours.fields.status'),
  },
  {
    name: 'workType',
    label: i18n('entities.workHours.fields.workType'),
    render: exporterRenders.relatedField('name'),
  },
  {
    name: 'date',
    label: i18n('entities.workHours.fields.date'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.workHours.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.workHours.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
