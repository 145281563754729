import WorkHoursService from 'src/modules/workHours/workHoursService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'WORKHOURS_VIEW';

const workHoursViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: workHoursViewActions.FIND_STARTED,
      });

      const record = await WorkHoursService.find(id);

      dispatch({
        type: workHoursViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: workHoursViewActions.FIND_ERROR,
      });

      getHistory().push('/work-hours');
    }
  },
};

export default workHoursViewActions;
