import list from 'src/modules/member/list/memberListReducers';
import form from 'src/modules/member/form/memberFormReducers';
import view from 'src/modules/member/view/memberViewReducers';
import destroy from 'src/modules/member/destroy/memberDestroyReducers';
import importerReducer from 'src/modules/member/importer/memberImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
