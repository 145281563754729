import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/workType/importer/workTypeImporterSelectors';
import WorkTypeService from 'src/modules/workType/workTypeService';
import fields from 'src/modules/workType/importer/workTypeImporterFields';
import { i18n } from 'src/i18n';

const workTypeImporterActions = importerActions(
  'WORKTYPE_IMPORTER',
  selectors,
  WorkTypeService.import,
  fields,
  i18n('entities.workType.importer.fileName'),
);

export default workTypeImporterActions;