import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import workHoursEnumerators from 'src/modules/workHours/workHoursEnumerators';
import moment from 'moment';

export default [
  {
    name: 'member',
    label: i18n('entities.workHours.fields.member'),
    schema: schemas.relationToOne(
      i18n('entities.workHours.fields.member'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'status',
    label: i18n('entities.workHours.fields.status'),
    schema: schemas.enumerator(
      i18n('entities.workHours.fields.status'),
      {
        "options": workHoursEnumerators.status
      },
    ),
  },
  {
    name: 'workType',
    label: i18n('entities.workHours.fields.workType'),
    schema: schemas.relationToOne(
      i18n('entities.workHours.fields.workType'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'date',
    label: i18n('entities.workHours.fields.date'),
    schema: schemas.date(
      i18n('entities.workHours.fields.date'),
      {
        "required": true
      },
    ),
   render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD') : value,
  },
];