import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/workHours/importer/workHoursImporterSelectors';
import WorkHoursService from 'src/modules/workHours/workHoursService';
import fields from 'src/modules/workHours/importer/workHoursImporterFields';
import { i18n } from 'src/i18n';

const workHoursImporterActions = importerActions(
  'WORKHOURS_IMPORTER',
  selectors,
  WorkHoursService.import,
  fields,
  i18n('entities.workHours.importer.fileName'),
);

export default workHoursImporterActions;