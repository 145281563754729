import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/member/importer/memberImporterSelectors';
import MemberService from 'src/modules/member/memberService';
import fields from 'src/modules/member/importer/memberImporterFields';
import { i18n } from 'src/i18n';

const memberImporterActions = importerActions(
  'MEMBER_IMPORTER',
  selectors,
  MemberService.import,
  fields,
  i18n('entities.member.importer.fileName'),
);

export default memberImporterActions;