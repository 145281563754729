import list from 'src/modules/workHours/list/workHoursListReducers';
import form from 'src/modules/workHours/form/workHoursFormReducers';
import view from 'src/modules/workHours/view/workHoursViewReducers';
import destroy from 'src/modules/workHours/destroy/workHoursDestroyReducers';
import importerReducer from 'src/modules/workHours/importer/workHoursImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
